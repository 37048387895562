<template>
    <div class="proCom2">
        <div class="pcNum">01</div>
        <!-- 左边大图片 -->
        <div class="pctLeftImg">
            <img class="pcrBigImg" src="http://iv.okvu.cn/vugw/img/pcdn.png" alt="" />
            <ul>
                <li class="pclLiImg1" v-show="showLImg === 1">
                    <img class="pclImg" src="http://iv.okvu.cn/vugw/img/pc121.jpg" alt="" />
                </li>
                <li class="pclLiImg2" v-show="showLImg === 2">
                    <img class="pclImg" src="http://iv.okvu.cn/vugw/img/pc122.jpg" alt="" />
                </li>
                <li class="pclLiImg3" v-show="showLImg === 3">
                    <img class="pclImg" src="http://iv.okvu.cn/vugw/img/pc123.jpg" alt="" />
                </li>
                <li class="pclLiImg4" v-show="showLImg === 4">
                    <img class="pclImg" src="http://iv.okvu.cn/vugw/img/pc124.jpg" alt="" />
                </li>
                <li class="pclLiImg4" v-show="showLImg === 5">
                    <img class="pclImg" src="http://iv.okvu.cn/vugw/img/pc125.jpg" alt="" />
                </li>
                <li class="pclLiImg4" v-show="showLImg === 6">
                    <img class="pclImg" src="http://iv.okvu.cn/vugw/img/pc126.jpg" alt="" />
                </li>
            </ul>
        </div>
        <!-- 右边文字小图片 -->
        <div class="pctRightText">
            <!-- 文字 -->
            <div class="pcrTop">
                <div class="pcrIcon">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="pcrBig">企业品牌宣传型</div>
                <div class="pcrSmall">
                    对于产品品类不多的企业，企业品牌宣传型网站着重展示企业CI、传播品牌文化、 提高品牌知名度，运用场景是长期化的。
                </div>
            </div>
            <!-- 小图片 -->
            <div class="pcrBottom">
                <ul>
                    <li class="pcrLiImg" @mousemove="showLeft(1)">
                        <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/pc121.jpg" alt="" />
                    </li>
                    <li class="pcrLiImg" @mousemove="showLeft(2)">
                        <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/pc122.jpg" alt="" />
                    </li>
                    <li class="pcrLiImg" @mousemove="showLeft(3)">
                        <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/pc123.jpg" alt="" />
                    </li>
                    <li class="pcrLiImg" @mousemove="showLeft(4)">
                        <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/pc124.jpg" alt="" />
                    </li>
                    <li class="pcrLiImg" @mousemove="showLeft(5)">
                        <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/pc125.jpg" alt="" />
                    </li>
                    <li class="pcrLiImg" @mousemove="showLeft(6)">
                        <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/pc126.jpg" alt="" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProCom2",
    data() {
        return {
            showLImg: 1,
        };
    },
    methods: {
        showLeft(i) {
            this.showLImg = i;
        },
    },
};
</script>

<style lang="less" scoped>
.proCom2 {
    max-width: 1920px;
    height: 700px;
    margin: 0 auto;
    background-color: white;
    position: relative;
    overflow: hidden;
    .pcNum {
        position: absolute;
        right: -22px;
        top: -90px;
        font-family: "DIN-Medium";
        font-size: 400px;
        color: #ff6c00;
        opacity: 0.05;
    }
    // 左边大图片
    .pctLeftImg {
        width: 606px;
        height: 500px;
        margin-top: 100px;
        float: left;
        position: relative;
        overflow: hidden;
        margin-left: 50px;
        .pcrBigImg {
            width: 606px;
            height: 500px;
        }
        ul {
            width: 100%;
            //   height: 100%;
            .pclLiImg1,
            .pclLiImg2,
            .pclLiImg3,
            .pclLiImg4 {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 13px;
                left: 0px;
                .pclImg {
                    width: 582px;
                    height: 357px;
                    border-radius: 5px;
                }
            }
        }
    }
    // 右边文字小图片
    .pctRightText {
        width: 40%;
        height: 100%;
        float: right;
        margin-right: 100px;
        // 文字
        .pcrTop {
            width: 100%;
            height: 300px;
            margin-top: 100px;
            .pcrIcon {
                width: 73px;
                height: 75px;
                overflow: hidden;
                position: relative;
                img {
                    position: absolute;
                    top: -140px;
                    left: -178px;
                }
            }
            .pcrBig {
                width: 100%;
                height: 100px;
                line-height: 100px;
                font-size: 40px;
                font-weight: 900;
            }
            .pcrSmall {
                width: 100%;
                height: 100px;
                margin-top: 30px;
                line-height: 30px;
                text-align: left;
            }
        }
        // 小图片
        .pcrBottom {
            width: 100%;
            height: 200px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .pcrLiImg {
                    width: 30%;
                    height: 45%;
                    overflow: hidden;
                    border-radius: 10px;
                    cursor: pointer;
                    .pcrImg {
                        width: 100%;
                        height: 100%;
                        transition: 0.5s;
                    }
                }
                .pcrLiImg:hover {
                    box-shadow: 1px 1px 10px 1px gray;
                    .pcrImg {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
</style>
