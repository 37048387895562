<template>
    <div class="proContent">
        <!-- 内容一 -->
        <ProCom1></ProCom1>
        <!-- 内容二 -->
        <ProCom2></ProCom2>
        <!-- 内容三 -->
        <ProCom3></ProCom3>
        <!-- 内容四 -->
        <ProCom4></ProCom4>
    </div>
</template>

<script>
import ProCom1 from "./ProCom1.vue";
import ProCom2 from "./ProCom2.vue";
import ProCom3 from "./ProCom3.vue";
import ProCom4 from "./ProCom4.vue";

export default {
    name: "ProContent",
    components: {
        ProCom1,
        ProCom2,
        ProCom3,
        ProCom4,
    },
};
</script>

<style lang="less" scoped>
.proContent {
}
</style>
