<template>
    <div class="proCom1" v-if="getAdvData(28).children">
        <a :href="getAdvData(28).children[0].url" target="_blank" rel="noopener noreferrer">
            <img v-if="getAdvData(28).children[0].image" :src="getAdvData(28).children[0].image" class="p1Bg" />
            <div class="pc1Box">
                <div class="pc1Text wow animate__animated animate__slideInLeft">网站特别技术手法，如何展现品牌？</div>
                <div class="pc1Imgs">
                    <img :src="getAdvData(28).children[0].children[0].image" />
                </div>
                <img :src="getAdvData(28).children[0].children[1].image" class="pc1Logo" />
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "ProCom1",
    inject: ["getAdvData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.proCom1 {
    min-width: 1423px;
    height: 700px;
    position: relative;
    overflow: hidden;
    .p1Bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        // left: 50%;
        // transform: translateX(-50%);
        z-index: -1;
        object-fit: cover;
    }
    .pc1Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        .pc1Text {
            width: 300px;
            height: 100px;
            line-height: 50px;
            font-size: 30px;
            font-weight: 900;
            position: absolute;
            bottom: 100px;
            color: white;
        }
        .pc1Imgs {
            position: absolute;
            bottom: 0px;
            left: 200px;
        }
        .pc1Logo {
            position: absolute;
            top: 0;
            left: 400px;
            z-index: 555;
        }
    }
}
</style>
